/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useGlobalContext } from "../context";

const CertificateDropdown = ({ rowSelectionChange }) => {
  const [isCertificateLoaded, setCertificateLoaded] = useState(false);
  const { dropdown, language } = useGlobalContext();
  const [downloadID, setDownloadID] = useState();
  const [status, setStatus] = useState(" ");
  const [errorCode, setErrorCode] = useState(" ");
  const [URL, setURL] = useState(" ");
  const { t } = useTranslation();
  const [selectedCertificate, setCertificateSelected] = useState("1");
  const [options, setOptions] = useState([{ Id: "1", ReportType: "-" }]);
  const [errors, setError] = useState({});

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  //first certificate is used as selected one

  // update selectedCertificate as checkbox changes
  useEffect(() => {
    let newOptions = Object.values(rowSelectionChange);
    /* for testing multiple values:
    let newOptions = [
      { Id: "3", ReportType: "Test3" },
      { Id: "2", ReportType: "Test2" },
    ];*/
    if (newOptions.length == 0) {
      newOptions = [{ Id: "1", ReportType: "-" }];
    }
    setCertificateSelected(newOptions[0].Id);
    setOptions(newOptions);
  }, [rowSelectionChange]);

  const handleShow = () => {
    setShow(true);
    getDownloadID(selectedCertificate);
  };

  const openCertificate = () => {
    var newWindow = window.open(URL, "_blank");
    newWindow.document.title = "Product Pass Certificate";
  };

  // get download ID with first request, afterwards fetch certificate in repeated requests until PDF is there
  const getDownloadID = async (certificateID) => {
    const url = `https://portal.wika.com/serial/srv/serialreq.aspx?obj=dlinit&certid=${certificateID}&serial=${serialNumber}&reqapp=web.ExtSerial.wika.com&reqver=0.4a&format=json&lng=${language}`;
    const resp = await fetch(url);

    if (resp.status >= 200 && resp.status <= 299) {
      const result = await resp.json();
      // Status can also return 200 and ERROR as a Status when certificate wasn't found
      if (result.Status === "ERROR")
      {
        setCertificateLoaded(false);
        setStatus(result.Status);
        setErrorCode(result.ErrorCode);
        return;
      }
      setDownloadID(result.DownloadId);

      let intervalID = setInterval(async () => {
        const response = await fetch(
          `https://portal.wika.com/serial/srv/serialreq.aspx?obj=dllink&downloadid=${result.DownloadId}&serial=${serialNumber}&reqapp=web.ExtSerial.wika.com&reqver=0.4a&format=json&lng=${language}`
        );
        let jsonContent = await response.json();

        if (response.status >= 200 && response.status <= 299) {
          if (jsonContent.Status == "OK") {
            setCertificateLoaded(true);
            let certName = jsonContent.CertificateList[0];

            let newUrl =
              "https://portal.wika.com/serial/download/certificates/" +
              certName;
            setURL(newUrl);
            setStatus("OK");
            clearInterval(intervalID);
          } else {
            setCertificateLoaded(false);

            let errors = {};
            errors.datasheetBtn = t("app.datasheetButton_val_no");
            setError(errors);
            setStatus(jsonContent.Status);
            setErrorCode(jsonContent.ErrorCode);
          }
        } else {
          setCertificateLoaded(false);
          let errors = {};
          errors.datasheetBtn = t("app.datasheetButton_val_no");
          setError(errors);
          setStatus(jsonContent.Status);
          setErrorCode(jsonContent.ErrorCode);
        }
      }, 15000);
      if (
        resp.status == "OK" ||
        (status == "ERROR" && errorCode != "#DL_PENDING")
      ) {
        clearInterval(intervalID); // Stop the interval if the condition holds true
      }
    }
  };

  const handleClick = (e, certificateID) => {
    setCertificateSelected(certificateID);
  };

  return (
    <div className="wika-cert-div">
      <div
        id="wika-cert-dropdown"
        className={
          options[0].Id == 1 || options.length == 1
            ? "dropdown-inactive"
            : "dropdown"
        }
      >
        {/* selected certificate */}
        {options.length > 0 && (
          <button className="dropbtn">
            {options.map((filteredCert) => {
              if (filteredCert.Id == selectedCertificate) {
                return (
                  <div key={filteredCert.Id} className="wika-dd-wrapper">
                    <div className="wika-left-dd">
                      {filteredCert.ReportType}
                    </div>
                    {options.length > 1 && (
                      <div className="wika-right-dd">
                        <FontAwesomeIcon icon="caret-down" />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </button>
        )}

        {/* dropdown list of certificates except selected */}
        {options.length > 1 && (
          <div className="wika-dd-cert-content">
            {options.map((data, index) => {
              if (options[index].Id !== selectedCertificate) {
                // selected entry
                return (
                  <li
                    key={index}
                    style={{ listStyle: "none" }}
                    onClick={(e) => {
                      handleClick(e, data.Id);
                    }}
                  >
                    {options[index].Id !== selectedCertificate && (
                      <button
                        className="wika-dd-cert-content-selected"
                        id={data.Id}
                        onClick={(e) => {
                          handleClick(e, data.Id);
                        }}
                      >
                        {" "}
                        {data.ReportType}
                      </button>
                    )}
                  </li>
                );
              }
            })}
          </div>
        )}
      </div>

      <div className="wika-button-shadow" id="wika-open-dialog-btn">
        <div
          className={
            options[0].Id == 1 ? "wika-button-inactive" : "wika-button"
          }
          onClick={handleShow}
        >
          {t("tabCalibration.certificateButton")}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t("tabCalibration.downloadTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {!isCertificateLoaded && status != "ERROR" && (
          <div>
            <div>{t("tabCalibration.modalDescription")}</div>
            <div>{t("tabCalibration.description2")}</div>
              <div>
                <div className="wika-spinner-center">
                  <div className="spinner-border col" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
                <div className="">
                  {t("tabCalibration.downloadWaitingNotice")}
                </div>
              </div>
            </div>
          )}
          {status === "ERROR" && (
            <div>
              {t("tabCalibration.errorCert")}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="wika-button-shadow">
            <div className="wika-button-close" onClick={handleClose}>
              {t("tabCalibration.closeBtn")}
            </div>
          </div>

          {isCertificateLoaded && (
            <div className="wika-button-shadow">
              <div className="wika-button" onClick={openCertificate}>
                {t("tabCalibration.openCertificate")}
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CertificateDropdown;
